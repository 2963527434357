//
// Custom
//

@use 'sass:map';

.scroll-margin {
  scroll-margin-top: map.get($header, height) + map.get($subheader, height) +
    map.get($page, padding-y);
}

.sticky-top-size {
  top: map.get($header, height) + map.get($subheader, height) + map.get($page, padding-y);
}

.border-transparent {
  --#{$prefix}border-opacity: 0;
}

.underline-on-hover:hover {
  text-decoration: underline;
}

.width-image-10 {
  max-width: 10vw;
  min-width: 128px;
  height: auto;
}
