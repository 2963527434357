.landing-page {
    background-color: #e9e6e6;
    padding: 0rem 5rem 5rem 5rem;

    & .welcome-title {
        font-size: 4rem;
    }

    & .testimonial {
        margin-top: 5rem;
    }
}

.landing-page-footer {
    padding: 3rem 5rem 5rem 5rem;
}
